import { application } from "./application"

import HelloController from "./hello_controller"
import TrailingSixWeeksChartController from "./trailing_six_weeks_chart_controller";
import TrailingTwelveMonthsChartController from "./trailing_twelve_months_chart_controller";
import ScorecardMetricCategoryFormController from "./scorecard_metric_category_form_controller";

application.register("hello", HelloController)
application.register("trailing-six-weeks-chart", TrailingSixWeeksChartController)
application.register("trailing-twelve-months-chart", TrailingTwelveMonthsChartController)
application.register("scorecard-metric-category-form", ScorecardMetricCategoryFormController)
