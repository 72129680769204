import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categoriesList", "template"]

  connect() {
    this.indexCounter = this.categoriesListTarget.children.length
  }

  addCategory(event) {
    event.preventDefault()
    console.log(this.templateTarget.innerHTML)
    const content = this.templateTarget.innerHTML.replace(/INDEX/g, this.indexCounter)
    this.categoriesListTarget.insertAdjacentHTML('beforeend', content)
    this.indexCounter++
  }

  removeCategory(event) {
    event.preventDefault()
    const item = event.target.closest(".nested-fields")
    if (item.dataset.newRecord === "true") {
      item.remove()
    } else {
      item.style.display = 'none'
      item.querySelector("input[name*='_destroy']").value = 1
    }
  }
}