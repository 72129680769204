import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  connect() {
    if (this.isTurboPreview()) {
      return;
    }
    this.fetchChartData().then(data => {
      this.initializeChartWithData(data)
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  async fetchChartData() {
    try {
      const response = await fetch('/charts/trailing_six_weeks')
      return await response.json();
    } catch (error) {
      console.error('Error fetching chart data:', error)
    }
  }

  initializeChartWithData(data) {
    const formattedDates = this.getLastSixMondays();
    const options = {
      chart: {
        height: "100%",
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: { enabled: false },
        toolbar: { show: false },
        padding: { left: 12, right: 12, top: 0, bottom: 0 },
        zoom: { enabled: false }
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: function (val, opts) {
            return formattedDates[opts.dataPointIndex];
          }
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return seriesName + ":";
            }
          },
          formatter: function (value) {
            return '$' + value.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: [0, 0.55, 0],
          opacityTo: 0,
          shade: "#1C64F2",
          gradientToColors: ["#1C64F2"],
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: [2, 3, 0],
        dashArray: [2, 0],
      },
      markers: {
        size: [0.5, 0.5, 4],
        strokeWidth: [0, 0, 0],
        hover: { sizeOffset: 2 },
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: { left: 2, right: 2, top: 0 },
      },
      series: [
        {
          name: "MRR (Prior Year)",
          data: data.prior_year_data.map(Number),
          color: "#9CA3AF",
        },
        {
          name: "MRR",
          data: data.current_data.map(Number),
          color: "#1A56DB",
        },
        {
          name: "Goal",
          data: [7000, 7000, 7000, 7000, 7000, 7000],
          color: "#16A34A",
        }
      ],
      xaxis: {
        categories: formattedDates,
        labels: {
          show: true,
          offsetY: 5,
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      yaxis: {
        show: true,
        labels: {
          formatter: function (value) {
            return '$' + value.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
          },
          offsetX: -10,
        },
        title: {
          text: 'Revenue ($)',
          offsetX: -10,
        },
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 10,
      },
    }

    if (typeof ApexCharts !== 'undefined') {
      this.chart = new ApexCharts(this.element, options);
      this.chart.render();
    }
  }

  getLastSixMondays() {
    const today = new Date();
    let lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() - ((today.getDay() + 6) % 7) - 7);
    const mondays = [];

    for (let i = 5; i >= 0; i--) {
      const monday = new Date(lastMonday);
      monday.setDate(monday.getDate() - (i * 7));
      mondays.push(this.formatDate(monday));
    }

    return mondays;
  }

  formatDate(date) {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  isTurboPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }
}